<template>
  <section>
    <el-table
      :header-cell-style="{
        'background-color': '#f5f7fa',
      }"
      border
      :data="ListData"
      class="tabBorder custor"
      :span-method="objectSpanMethod"
      v-horizontal-scroll
      style="margin-top: 0"
      v-loading="listLoading"
      ref="handSelectTest_multipleTable"
      @selection-change="handleSelectionChange"
      @select="handleSelect"
      v-sticky="{ top: 0, parent: '.scollDom' }"
    >
      <el-table-column
        type="selection"
        width="40"
        fixed
        v-if="tableStatus !== 'PAID' && !chioceIds"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="140"
        fixed
        v-if="tableStatus !== 'PAID' && !chioceIds"
      >
        <template slot-scope="scope">
          <span class="tabHref" @click="delectData(scope.row.id)"> 删除</span>
          <el-divider direction="vertical"></el-divider>
          <span class="tabHref" @click="measurements(scope.row)">
            重新测算</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        fixed
        min-width="180"
        class-name="canEdit"
      >
        <template slot="header">
          <span class="header-label">顾问</span>
          <el-popover
            v-if="!chioceIds"
            title="搜索"
            popper-class="popperEdit"
            placement="bottom"
            trigger="click"
          >
            <i slot="reference" class="heardSearch el-icon-search"></i>
            <div class="popcon">
              <div class="popcon-input">
                <el-cascader
                  style="width: 240px"
                  clearable
                  size="mini"
                  filterable
                  :show-all-levels="false"
                  :options="options1"
                  collapse-tags
                  ref="dataRef"
                  v-model="getuserIds"
                  :props="{
                    value: 'id',
                    label: 'name',
                    children: 'children',
                    multiple: true,
                    checkStrictly: false,
                  }"
                  @change="getSearch"
                  :filter-method="filterFun"
                ></el-cascader>
              </div>
            </div>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <div style="display: flex">
            <p :title="scope.row.userName">
              {{ scope.row.userName }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="workId" width="80" label="工号">
        <template slot-scope="scope">
          <span
            v-if="
              !scope.row.workId &&
              (!scope.row.calculateModelName ||
                '寻访员' == scope.row.calculateModelName)
            "
            style="
              position: absolute;
              z-index: 2;
              color: #f56c6c;
              background: #ffeff0;
              padding: 2px 10px;
              border: 1px solid #fee2e1;
              display: block;
              width: 406px;
              margin-top: -15px;
              border-radius: 2px;
            "
          >
            没有设置奖金计算模型（系统将在下一步自动过滤此类数据）<span
              class="tabHref"
              @click="setModels(scope.row)"
              >点此设置</span
            ></span
          >
          <span v-else>{{ scope.row.workId }}</span>
        </template>
      </el-table-column>
      <el-table-column width="150" class-name="canEdit">
        <template slot="header">
          <span class="header-label">所属团队 </span>
          <el-popover
            v-if="!chioceIds"
            title="搜索"
            popper-class="popperEdit"
            placement="bottom"
            trigger="click"
          >
            <i slot="reference" class="heardSearch el-icon-search"></i>
            <div class="popcon">
              <div class="popcon-input">
                <el-cascader
                  style="width: 240px"
                  clearable
                  size="mini"
                  filterable
                  :show-all-levels="false"
                  :options="options2"
                  collapse-tags
                  ref="dataRef1"
                  v-model="departmentIds"
                  :props="{
                    value: 'id',
                    label: 'name',
                    children: 'children',
                    multiple: true,
                    checkStrictly: false,
                  }"
                  @change="getDepartmentIdsSearch"
                  :filter-method="filterFun"
                ></el-cascader>
              </div>
            </div>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.departmentName ? scope.row.departmentName : "-" }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="140" class-name="canEdit">
        <template slot="header">
          <span class="header-label">{{tableStatus == "PAID"?"总计提奖金":"总待计提奖金"}}</span>
          <el-tooltip class="item" effect="dark" placement="bottom" content="累计应发总奖金 - 累计应发已计提">
            <i
              class="iconfont icon icon-wenhao-xiangsu"
              style="color: #898989; font-size: 14px; font-weight: 400"
            ></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <div style="display: flex">
            <span style="margin-right: 10px">{{ scope.row.totalBonus }}</span>
            <div @click.stop="openDialog(scope.row, scope.$index)">
              <div
                v-if="isaddMark"
                @mouseover="showMark(scope.row, '人才')"
                @mouseout="clearSelect()"
                class="tabHref"
              >
                <i class="iconfont icon-pl_bz"></i>
              </div>
              <div v-if="!isaddMark">
                <el-popover
                  v-if="scope.row.bonusComment"
                  placement="bottom-start"
                  width="450"
                  trigger="hover"
                  popper-class="popperH"
                  :open-delay="1000"
                >
                  <p>{{ scope.row.bonusComment }}</p>
                  <div slot="reference" class="tabHref">
                    <i class="iconfont icon-pl_bz"></i>
                  </div>
                </el-popover>
                <div class="hoverIcon" v-if="!scope.row.bonusComment">
                  <i class="iconfont icon-pl_bz"></i>
                </div>
              </div>
            </div>
            <i
              v-if="tableStatus == 'GENERATED'"
              @click="editTotalBonus(scope.row, 'issued')"
              title="手动调整"
              class="el-icon-edit tabHref"
            ></i>
          </div>
          <p
              v-if="scope.row.bonusChangeReason"
              class="tabHref"
              @click="showRecond(scope.row, 'bonus')"
              >修改记录</p>
        </template>
      </el-table-column>
      <el-table-column width="180">
          <template slot="header">
            <span class="header-label" style="font-size: 12px"
              >累计应发已计提
            </span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                指测算年度内已计提和待计提的奖金总额。<br />
                待确认计提表的奖金已被锁定额度。</template
              >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.accruedPaidBonus !== null"
              @click="showPerformanceDram('accruedPaidBonus', scope.row)"
            >
              {{ scope.row.accruedPaidBonus }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
      <el-table-column width="150" label="备注" class-name="canEdit">
        <template slot-scope="scope">
          <span>
            {{ scope.row.comment ? scope.row.comment : "-" }}
          </span>
          <i
            @click="editComment(scope.row)"
            class="heardSearch el-icon-edit tabHref"
          ></i>
        </template>
      </el-table-column>
      <el-table-column width="150" class-name="canEdit">
        <template slot="header">
          <span class="header-label">职级 </span>
          <el-popover
            v-if="!chioceIds"
            title="搜索"
            popper-class="popperEdit"
            placement="bottom"
            trigger="click"
          >
            <i slot="reference" class="heardSearch el-icon-search"></i>
            <div class="popcon">
              <div class="popcon-input">
                <el-select
                  multiple
                  collapse-tags
                  clearable
                  v-model="filter.calculateModelNames"
                  style="width: 100%"
                  @change="submitForm()"
                >
                  <el-option
                    v-for="(item, index) in rankList"
                    :key="index"
                    :value="item.modelName"
                    :label="item.modelName"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <p>
            {{
              scope.row.calculateModelName ? scope.row.calculateModelName : "-"
            }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="100" class-name="canEdit">
        <template slot="header">
          <span class="header-label">入职时间 </span>
          <el-popover
            v-if="!chioceIds"
            title="搜索"
            popper-class="popperEdit"
            placement="bottom"
            trigger="click"
          >
            <i slot="reference" class="heardSearch el-icon-search"></i>
            <div class="popcon">
              <div class="popcon-input">
                <el-date-picker
                  v-model="startTime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="submitForm()"
                >
                </el-date-picker>
              </div>
            </div>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.entryDate ? scope.row.entryDate : "-" }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="100" class-name="canEdit">
        <template slot="header">
          <span class="header-label">离职时间 </span>
          <el-popover
            v-if="!chioceIds"
            title="搜索"
            popper-class="popperEdit"
            placement="bottom"
            trigger="click"
          >
            <i slot="reference" class="heardSearch el-icon-search"></i>
            <div class="popcon">
              <div class="popcon-input">
                <el-date-picker
                  v-model="endTime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="submitForm()"
                >
                </el-date-picker>
              </div>
            </div>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.leaveDate ? scope.row.leaveDate : "-" }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template slot="header">
          <span class="header-label">开票周期 </span>
          <el-tooltip
            class="item"
            effect="dark"
            content="指对应职级的开票周期。根据查询的“开票时间”自动拆分。"
            placement="bottom"
          >
            <i
              class="iconfont icon icon-wenhao-xiangsu"
              style="color: #898989; font-size: 14px; font-weight: 400"
            ></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <p v-if="scope.row.invoiceStartDate">
            {{
              scope.row.invoiceStartDate ? scope.row.invoiceStartDate : "-"
            }}至{{ scope.row.invoiceEndDate ? scope.row.invoiceEndDate : "-" }}
          </p>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column width="100" label="开票业绩">
        <template slot-scope="scope">
          <p
            class="tabHref"
            v-if="scope.row.invoiceMoney"
            @click="showPerformanceDram('Invoicing', scope.row)"
          >
            {{ scope.row.invoiceMoney }}
          </p>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template slot="header">
          <span class="header-label">累计应发总奖金 </span>
          <el-tooltip class="item" effect="dark" placement="bottom">
            <template slot="content">
              计算公式：累计应发总奖金=开票业绩 * 奖金比例 - 累计固定工资
              <br />计算规则：按开票时间找对应的奖金计算规则。按开票业绩计算奖金。</template
            >
            <i
              class="iconfont icon icon-wenhao-xiangsu"
              style="color: #898989; font-size: 14px; font-weight: 400"
            ></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <p
            class="tabHref"
            v-if="scope.row.accruedBonus !== null"
            @click="showPerformanceDram('accruedBonus', scope.row)"
          >
            {{ scope.row.accruedBonus }}
          </p>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column width="180">
        <template slot="header">
          <span class="header-label" style="font-size: 12px"
            >累计固定工资（包含待确认计提表的锁定额）
          </span>
          <el-tooltip class="item" effect="dark" placement="bottom">
            <template slot="content">
              特别说明：<br />
              寻访员奖金与固定工资无关，默认按0处理</template
            >
            <i
              class="iconfont icon icon-wenhao-xiangsu"
              style="color: #898989; font-size: 14px; font-weight: 400"
            ></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <p
            class="tabHref"
            v-if="scope.row.accruedSalary !== null"
            @click="showPerformanceDram('accruedSalary', scope.row)"
          >
            {{ scope.row.accruedSalary }}
          </p>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column
        width="180"
        v-if="tableStatus !== 'GENERATED' && !chioceIds"
      >
        <template slot="header">
          <span class="header-label">开票时间 </span>
          <el-tooltip
            class="item"
            effect="dark"
            content="指测算时“开票时间”查询条件值"
            placement="bottom"
          >
            <i
              class="iconfont icon icon-wenhao-xiangsu"
              style="color: #898989; font-size: 14px; font-weight: 400"
            ></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <p v-if="scope.row.recordInvoiceStartDate">
            {{
              scope.row.recordInvoiceStartDate
                ? scope.row.recordInvoiceStartDate
                : "-"
            }}至{{
              scope.row.recordInvoiceEndDate
                ? scope.row.recordInvoiceEndDate
                : "-"
            }}
          </p>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="yearValue"
        width="100"
        label="所属年份"
        v-if="tableStatus !== 'GENERATED' && !chioceIds"
      >
      </el-table-column>
      <el-table-column
        prop="planPayDate"
        width="100"
        label="所属月份"
        v-if="tableStatus !== 'GENERATED' && !chioceIds"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.planPayDate">
            {{ scope.row.planPayDate.substring(0, 7) }}
          </p>
          <p v-else>-</p>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisibleSingle"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        v-if="totalBonus"
        @submit.native.prevent
        :model="totalBonusData"
        ref="totalBonusData"
        label-width="100px"
      >
        <el-form-item label="当前金额：">
          <p>{{ totalBonusData.totalBonus }}</p>
        </el-form-item>
        <el-form-item
          label="修改后金额："
          prop="changeBonus"
          :rules="[
            { required: true, message: '请输入修改后金额', trigger: 'blur' },
          ]"
        >
          <el-input
            placeholder="修改后金额"
            v-model="totalBonusData.changeBonus"
          >
            <template #append> 元 </template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="修改原因："
          prop="bonusChangeReason"
          :rules="[
            { required: true, message: '请输入修改原因', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="totalBonusData.bonusChangeReason"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form
        v-if="comment || isaddMark"
        @submit.native.prevent
        :model="commentData"
        ref="commentData"
        label-width="120px"
      >
        <el-form-item label="顾问：">
          <p>{{ commentData.userName }}</p>
        </el-form-item>
        <el-form-item
          v-if="isaddMark"
          :label="tableStatus == 'PAID' ? '总计提奖金：' : '总待计提奖金：'"
        >
          <p>{{ commentData.totalBonus }}元</p>
        </el-form-item>
        <el-form-item label="备注：" prop="comment">
          <el-input
            v-model="commentData.comment"
            type="textarea"
            :maxlength="500"
          ></el-input>
          <p style="color: #999; font-size: 12px">
            如果您已完成备注事项，可清空备注内容
          </p>
        </el-form-item>
      </el-form>
      <el-form
        v-if="models"
        @submit.native.prevent
        :model="modelsData"
        ref="modelsData"
        label-width="150px"
      >
        <el-form-item
          label="工号："
          prop="workId"
          :rules="[{ required: true, message: '请输入工号', trigger: 'blur' }]"
        >
          <el-input v-model="modelsData.workId" style="width: 80%"> </el-input>
        </el-form-item>
        <el-form-item
          label="所属团队："
          prop="departmentId"
          class="is-required"
        >
          <el-cascader
            style="width: 80%"
            :filterable="true"
            :options="options1"
            clearable
            collapse-tags
            :show-all-levels="false"
            v-model="departmentId"
            :props="{
              value: 'id',
              label: 'name',
              children: 'children',
              checkStrictly: true,
            }"
            placeholder="请选择所属团队"
            ref="dataRef2"
            @change="getDepartmentId"
            popper-class="selectCascaderPop"
            :filter-method="filterFun"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="奖金计算模型名称："
          prop="calculateModelId"
          :rules="[
            {
              required: true,
              message: '请选择奖金计算模型名称',
              trigger: 'change',
            },
          ]"
        >
          <el-select
            v-model="modelsData.calculateModelId"
            placeholder="请选择"
            filterable
            style="width: 80%"
          >
            <el-option
              v-for="(item, index) in nameList"
              :key="'name_' + index"
              :label="item.modelName"
              :value="item.modelId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="固定工资（元/月）："
          prop="fixedSalary"
          :rules="[
            {
              required: true,
              message: '请输入固定工资（元/月）',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="modelsData.fixedSalary" style="width: 80%">
          </el-input>
        </el-form-item>
        <el-form-item
          label="入职时间："
          prop="startDate"
          :rules="[
            {
              required: true,
              message: '请选择入职时间',
              trigger: 'change',
            },
          ]"
        >
          <el-date-picker
            v-model="modelsData.startDate"
            type="date"
            placeholder="选择日期"
            style="width: 80%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="入职月在职天数："
          prop="entryMonthWorkDays"
        >
          <el-input v-model="modelsData.entryMonthWorkDays" style="width: 80%">
          </el-input>
          <el-checkbox
            v-model="modelsData.entryMonthFull"
            label="满月"
            ></el-checkbox>
        </el-form-item>
        <el-form-item label="离职时间：" prop="endDate">
          <el-date-picker
            v-model="modelsData.endDate"
            type="date"
            placeholder="选择日期"
            style="width: 80%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="离职月在职天数：" prop="leaveMonthWorkDays">
          <el-input v-model="modelsData.leaveMonthWorkDays" style="width: 80%">
          </el-input>
          <el-checkbox
            v-model="modelsData.leaveMonthFull"
            label="满月"
            ></el-checkbox>
        </el-form-item>
        <el-form-item label="发放主体：" prop="payrollEntity">
          <el-input v-model="modelsData.payrollEntity" style="width: 80%">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="handleClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveInfos"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-drawer
      :visible.sync="drawerVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      :before-close="handleDrawerClose"
    >
      <div slot="title" class="drawerTitle">
        {{ drawerName }}
      </div>
      <el-row v-if="drawerVisible">
        <el-col
          style="
            padding: 10px 15px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <el-button
            v-if="drawType !== 'accruedBonus'"
            size="mini"
            @click="downInfoExp"
            :loading="btnLoading"
            >导出</el-button
          >
          <span v-if="drawType == 'accruedSalary'"
            >累计固定工资总额：
            <span style="color: orange; font-weight: 600"
              >{{ totalSalary }}
            </span>
            元</span
          >
          <el-pagination
            :hide-on-single-page="page.total > 10 ? false : true"
            v-if="page.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="page.size"
            layout="total,prev, pager, next,sizes"
            :total="page.total"
            style="padding-right: 0px"
          ></el-pagination>
        </el-col>
        <el-col>
          <el-table
            v-if="drawType == 'Invoicing' || drawType == 'Payback'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              v-if="drawType == 'Payback'"
              label="回款时间"
              width="150"
              prop=""
            >
              <template slot-scope="scope">
                {{
                  scope.row.lastModifiedTime
                    ? scope.row.lastModifiedTime.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="drawType == 'Payback'"
              label="开票时间"
              width="150"
              prop=""
            >
              <template slot-scope="scope">
                {{
                  scope.row.actualMakeInvoiceDate
                    ? scope.row.actualMakeInvoiceDate.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column v-else label="时间" width="150" prop="">
              <template slot-scope="scope">
                {{
                  scope.row.lastModifiedTime
                    ? scope.row.lastModifiedTime.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="总业绩"></el-table-column>
            <el-table-column
              prop="achievement"
              width="150"
              label="实际业绩"
            ></el-table-column>
            <el-table-column
              label="业绩创造者"
              prop="nickName"
              width="150"
            ></el-table-column>
            <el-table-column
              label="业绩类型"
              width="150"
              prop="roleName"
            ></el-table-column>
            <el-table-column
              label="分配比例（个人）"
              width="160"
              prop="allocatePercentStr"
            >
              <template slot-scope="scope">
                {{
                  scope.row.allocatePercentStr
                    ? scope.row.allocatePercentStr
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column label="项目名称" show-overflow-tooltip>
              <template #default="scope">
                <router-link
                  class="tabHref"
                  target="_blank"
                  :to="{
                    path: '/project/details',
                    query: { id: scope.row.projectId },
                  }"
                  >{{ scope.row.projectName }}
                </router-link>
              </template></el-table-column
            >
            <el-table-column label="候选人" width="120">
              <template #default="scope">
                <span class="tabHref" @click="showDrawer(scope.row.resumeId)">{{
                  scope.row.resumeName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="industryName" label="行业">
              <template #default="scope">
                {{ scope.row.industryName ? scope.row.industryName : "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedBonus'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              prop="userName"
              label="顾问"
              min-width="180"
            ></el-table-column>
            <el-table-column label="开票周期" min-width="180">
              <template slot-scope="scope">
                <p v-if="scope.row.invoiceStartDate">
                  {{
                    scope.row.invoiceStartDate
                      ? scope.row.invoiceStartDate
                      : "-"
                  }}至{{
                    scope.row.invoiceEndDate ? scope.row.invoiceEndDate : "-"
                  }}
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="calculateModelName"
              label="职级"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="invoiceMoney"
              label="开票业绩"
            ></el-table-column>
            <el-table-column label="*" width="40">
              <template slot-scope="scope">
                <p>*</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="baseRatio"
              label="比例"
              width="80"
            ></el-table-column>
            <el-table-column label="-" width="40">
              <template slot-scope="scope">
                <p>-</p>
              </template>
            </el-table-column>
            <el-table-column prop="accruedSalary" min-width="180">
              <template slot="header">
                <span class="header-label">累计固定工资 </span>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <template slot="content">
                    特别说明：<br />
                    寻访员奖金与固定工资无关，默认按0处理</template
                  >
                  <i
                    class="iconfont icon icon-wenhao-xiangsu"
                    style="color: #898989; font-size: 14px; font-weight: 400"
                  ></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <p v-if="scope.row.accruedSalary">
                  {{ scope.row.accruedSalary }}
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column label="=" width="40">
              <template slot-scope="scope">
                <p>=</p>
              </template>
            </el-table-column>
            <el-table-column prop="accruedBonus" width="180">
              <template slot="header">
                <span class="header-label" style="color: #e60012"
                  >累计应发总奖金
                </span>
              </template>
              <template slot-scope="scope">
                <p style="color: #e60012">
                  {{ scope.row.accruedBonus }}
                </p>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedPaidBonus'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              prop="bonusTableName"
              label="计提表名称"
            ></el-table-column>
            <el-table-column
              prop="workId"
              label="工号"
            ></el-table-column>
            <el-table-column
              prop="yearValue"
              label="计提奖金所属年份"
            ></el-table-column>
            <el-table-column
              prop="paidBonus"
              label="计提金额（元）"
            ></el-table-column>
            <el-table-column prop="payStatus" label="确认状态">
              <template slot-scope="scope">
                <p
                  :style="
                    scope.row.payStatus == '待发放' ? 'color: #526ecc' : ''
                  "
                >
                  {{ scope.row.payStatus }}
                </p>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedSalary'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column prop="monthValue" label="月份"></el-table-column>
            <el-table-column prop="workTime" label="在职时长"></el-table-column>
            <el-table-column prop="salary" label="固定工资金额">
              <template slot-scope="scope">
                <span style="color: orange">
                  {{ scope.row.salary }}
                </span>
                <i
                  v-if="tableStatus == 'GENERATED'"
                  @click="editTotalBonus(scope.row, 'accruedSalary')"
                  title="手动调整"
                  class="el-icon-edit tabHref"
                  style="margin: 0 8px"
                ></i>
                <span
                  v-if="scope.row.previousSalary && tableStatus == 'GENERATED'"
                  class="tabHref"
                  @click="showRecond(scope.row, 'accruedSalary')"
                  >修改记录</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerResumeVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleDrawerClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerResumeVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  bonusCalculateModelOptionList, //获取奖金计算模型下拉列表
  bonusDataGeneratedList, //获取测算结果数据
  bonusDataToPayList, //获取待发奖金数据
  bonusDataPaidList, //获取已发奖金数据
  bonusDataDelete, //删除奖金数据
  accrualCalculateSscReCalculate, //测算表重新测算
  accrualCalculateSscReCalculateToPay, //待发表重新测算
  accrualCalculateSscCalculateUser, //测算选取的顾问
  bonusRecordEditComment, //备注
  bonusRecordEditBonus, //奖金
  bonusRecordEditTotalBonusCmment, //编辑总代发奖金备注
  bonusRecordItemAccruedBonusList, //累计已发奖金列表
  bonusRecordItemExportAccruedBonus, //导出累计已发奖金列表
  bonusSalaryRecordList, //工资记录列表
  bonusSalaryRecordExport, //导出工资记录列表
  bonusSalaryRecordModify, //修改工资
  bonusRecordItemAccruedBonusInfo, //累计总奖金详情
  accrualDataExportToPayList, //导出待发奖金数据
  accrualDataExportPaidList, //导出获取已发奖金数据
  bonusModelUserSettingNormalSet, //顾问奖金设置
  bonusAchievementDetailAccount, //回款业绩详情
  bonusAchievementDetailExportAccount, //导出回款业绩
  bonusAchievementDetailInvoice, //开票业绩详情
  bonusAchievementDetailExportInvoice, //导出开票业绩
  permissionTree, //获取顾问
  bonusCalculateModelBonusOptionList, //奖金表获取下拉列表
  accrualDataExportGeneratedList, //导出核对测算表
} from "../../api/api";
import resumeDetails from "../resumeDetail";
import { ApiBaseUrl } from "@/api/http";
export default {
  props: [
    "filter",
    "tableStatus",
    "chioceIds",
    "tableId",
    "userIds",
    "options",
  ],
  components: {
    resumeDetails,
  },
  computed: {
    activeCollapse() {
      return this.remarkDatas.map((i, index) => {
        return "item_" + index;
      });
    },
  },
  data() {
    return {
      baseUrl: ApiBaseUrl,
      ListData: [],
      listLoading: false,
      multipleSelection: [],
      searchInfo: {},
      rankList: [],
      startTime: [],
      endTime: [],
      isaddMark: false,
      remarkDatas: [],
      timer: null,
      time: false,
      titName: "",
      dialogVisibleSingle: false,
      totalBonus: false,
      saveloading: false,
      totalBonusData: {
        totalBonus: null,
        itemId: null,
        changeBonus: "",
        bonusChangeReason: "",
      },
      comment: false,
      commentData: {
        userName: null,
        id: null,
        comment: "",
      },
      models: false,
      modelsData: {},
      nameList: [],
      drawerVisible: false,
      btnLoading: false,
      page: {
        current: 1,
        size: 50,
        total: 0,
      },
      InfoData: [],
      resumeId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      drawerResumeVisible: false,
      drawerName: "",
      drawType: "",
      ids: [],
      spanArr: [],
      DrawData: {},
      totalSalary: 0,
      options1: [],
      options2: [],
      getuserIds: [],
      departmentIds: [],
      departmentId: [],
      cleanId:null
    };
  },
  mounted() {
    if (this.chioceIds || this.userIds) {
      this.getBonusCalculateSscReCalculate();
    }
    this.getRankList();
    this.permissionTree("顾问");
    this.permissionTree("团队");
  },
  methods: {
    handleSelectionChange(val) {
      //表格选择
      this.multipleSelection = val;
      if (this.cleanId !== null) {
        this.ids = val.map((i) => {
          if (i.id !== this.cleanId) {
            return i.id;
          }
        });
      } else {
        this.ids = val.map((i) => i.id);
      }
      this.$emit("getChioceId", this.ids);
    },
    handleSelect(rows, row) {
      let selected = rows.length && rows.indexOf(row) !== -1;
      if (!selected) {
        this.cleanId = row.id;
      } else {
        this.cleanId = null;
      }
    },
    getAllMoney() {
      let money = 0;
      let ids = new Set();
      for (var i = 0; i < this.ListData.length; i++) {
        if(!ids.has(this.ListData[i].id)){
          money +=
              this.ListData[i].totalBonus !== null
                ? this.ListData[i].totalBonus
                : 0;
          ids.add(this.ListData[i].id)
        }
      }

      this.$emit("moneyComputer", money);
    },
    submitForm() {
      this.filter.entryDateStart = this.startTime ? this.startTime[0] : "";
      this.filter.entryDateEnd = this.startTime ? this.startTime[1] : "";
      this.filter.leaveDateStart = this.endTime ? this.endTime[0] : "";
      this.filter.leaveDateEnd = this.endTime ? this.endTime[1] : "";
      this.loadList();
    },
    //获取列表数据
    loadList() {
      this.listLoading = true;
      if (this.tableStatus == "GENERATED") {
        bonusDataGeneratedList(this.filter).then((res) => {
          if (res.success) {
            this.ListData = res.result.listData;
            let pos = 0;
            this.spanArr = [];
            for (var i = 0; i < this.ListData.length; i++) {
              if (i === 0) {
                this.spanArr.push(1);
                pos = 0;
              } else {
                // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
                if (this.ListData[i].id === this.ListData[i - 1].id) {
                  this.spanArr[pos] += 1;
                  this.spanArr.push(0);
                } else {
                  this.spanArr.push(1);
                  pos = i;
                }
              }
            }
          }
          this.listLoading = false;
        });
      } else if (this.tableStatus == "TO_PAY") {
        this.filter.planPayStart = this.filter.planPayDate
          ? this.filter.planPayDate[0]
          : "";
        this.filter.planPayEnd = this.filter.planPayDate
          ? this.filter.planPayDate[1]
          : "";
        bonusDataToPayList(this.filter).then((res) => {
          if (res.success) {
            this.ListData = res.result;
            let pos = 0;
            this.spanArr = [];
            for (var i = 0; i < this.ListData.length; i++) {
              if (i === 0) {
                this.spanArr.push(1);
                pos = 0;
              } else {
                // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
                if (this.ListData[i].id === this.ListData[i - 1].id) {
                  this.spanArr[pos] += 1;
                  this.spanArr.push(0);
                } else {
                  this.spanArr.push(1);
                  pos = i;
                }
              }
            }
          }
          this.listLoading = false;
        });
      } else if (this.tableStatus == "PAID") {
        this.filter.planPayStart = this.filter.planPayDate
          ? this.filter.planPayDate[0]
          : "";
        this.filter.planPayEnd = this.filter.planPayDate
          ? this.filter.planPayDate[1]
          : "";
        bonusDataPaidList(this.filter).then((res) => {
          if (res.success) {
            this.ListData = res.result.records;
            let pos = 0;
            this.spanArr = [];
            for (var i = 0; i < this.ListData.length; i++) {
              if (i === 0) {
                this.spanArr.push(1);
                pos = 0;
              } else {
                // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
                if (this.ListData[i].id === this.ListData[i - 1].id) {
                  this.spanArr[pos] += 1;
                  this.spanArr.push(0);
                } else {
                  this.spanArr.push(1);
                  pos = i;
                }
              }
            }
            this.$emit("getPage", res.result.total);
          }
          this.listLoading = false;
        });
      }
    },
    //导出表格
    downLoadTable() {
      if (this.tableStatus == "GENERATED") {
        accrualDataExportGeneratedList(this.filter).then((res) => {
          if (res.success) {
            location.href = this.baseUrl + res.result;
          }
        });
      } else if (this.tableStatus == "TO_PAY") {
        this.filter.planPayStart = this.filter.planPayDate[0]
          ? this.filter.planPayDate[0]
          : "";
        this.filter.planPayEnd = this.filter.planPayDate[1]
          ? this.filter.planPayDate[1]
          : "";
        console.log(this.filter);
        accrualDataExportToPayList(this.filter).then((res) => {
          if (res.success) {
            location.href = this.baseUrl + res.result;
          }
        });
      } else if (this.tableStatus == "PAID") {
        this.filter.payStart = this.filter.payDate
          ? this.filter.payDate[0]
          : "";
        this.filter.payEnd = this.filter.payDate ? this.filter.payDate[1] : "";
        accrualDataExportPaidList(this.filter).then((res) => {
          if (res.success) {
            location.href = this.baseUrl + res.result;
          }
        });
      }
    },
    //删除
    delectData(id) {
      let ids = [];
      ids = id ? [id] : this.ids;
      if (ids.length == 0) {
        this.$message.error("请选择要处理的数据");
        return;
      }
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bonusDataDelete({ ids: ids }).then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              this.loadList();
              if (this.tableStatus !== "TO_PAY") {
                this.$emit("getbonusDataTableInfo");
              }
            }
          });
        })
        .catch(() => {});
    },
    //编辑总待发奖金
    editTotalBonus(data, type) {
      this.dialogVisibleSingle = true;
      this.totalBonus = true;
      if (type == "issued") {
        this.titName = "修改待计提奖金";
        this.totalBonusData.totalBonus = data.totalBonus;
        this.totalBonusData.id = data.id;
      } else {
        this.titName = "修改固定工资金额";
        this.totalBonusData.totalBonus = data.salary;
        this.totalBonusData.id = data.salaryRecordId;
      }
    },
    //编辑备注
    editComment(data) {
      this.dialogVisibleSingle = true;
      this.comment = true;
      this.titName = "备注";
      this.commentData.id = data.id;
      this.commentData.userName = data.userName;
      this.commentData.comment = data.comment ? data.comment : null;
    },
    //设置模型
    setModels(data) {
      this.dialogVisibleSingle = true;
      this.models = true;
      this.titName = "设置奖金计算模型 - " + data.userName;
      this.getNameList();
      this.modelsData.userId = data.userId;
      this.modelsData.id = data.id;
      console.log(this.tableId);
    },
    //获取奖金计算模型下拉列表
    getNameList() {
      bonusCalculateModelOptionList().then((res) => {
        if (res.success) {
          this.nameList = res.result;
        }
      });
    },
    //获取职级
    getRankList() {
      bonusCalculateModelBonusOptionList().then((res) => {
        if (res.success) {
          this.rankList = res.result;
        }
      });
    },

    //
    openDialog(data, index) {
      this.isaddMark = true;
      this.dialogVisibleSingle = true;
      this.titName = "备注";
      this.commentData.id = data.id;
      this.commentData.userName = data.userName;
      this.commentData.totalBonus = data.totalBonus;
      this.commentData.comment = data.bonusComment;
    },
    showMark(row, type) {
      let _this = this;
      _this.time = false;
      _this.remarkDatas = [];
      this.timer = setTimeout(function () {
        _this.loadRecodeList(row, type);
      }, 1000);
    },
    loadRecodeList(row, type) {
      //加载备注记录List
      this.dialogVisibleSingle = false;
      this.remarkDatas = [];
    },
    clearSelect() {
      if (this.timer) {
        clearInterval(this.timer);
        this.time = false;
      }
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisibleSingle = false;
      this.totalBonus = false;
      this.titName = "";
      this.comment = false;
      this.models = false;
      this.isaddMark = false;
      this.clearData();
    },
    //数据清除
    clearData() {
      this.commentData = {
        totalBonus: null,
        itemId: null,
        changeBonus: null,
        bonusChangeReason: null,
        comment: null,
      };
      this.totalBonusData = {
        userName: null,
        id: null,
        comment: null,
      };
      this.modelsData = {
        departmentId: "",
      };
      this.departmentId = [];
    },
    //
    handleDrawerClose() {
      this.drawerVisible = false;
      this.drawerResumeVisible = false;
      this.page = { current: 1, size: 50, total: 0 };
    },
    //保存
    saveInfos() {
      let req = null;
      if (this.comment) {
        req = bonusRecordEditComment(this.commentData);
      } else if (this.totalBonus) {
        if (this.titName == "修改待计提奖金") {
          req = bonusRecordEditBonus(this.totalBonusData);
        } else {
          req = bonusSalaryRecordModify({
            id: this.totalBonusData.id,
            currentSalary: this.totalBonusData.totalBonus,
            changedSalary: this.totalBonusData.changeBonus,
            changeReason: this.totalBonusData.bonusChangeReason,
          });
        }
      } else if (this.isaddMark) {
        req = bonusRecordEditTotalBonusCmment(this.commentData);
      } else if (this.models) {
        if (this.modelsData.departmentId.length == 0) {
          this.$message.error("请选择所属团队");
          return;
        }
        this.$refs["modelsData"].validate((valid) => {
          if (valid) {
            let id = this.modelsData.id;
            this.modelsData.id = null;
            bonusModelUserSettingNormalSet({
              userBonusModelSettings: [this.modelsData],
              continueFromExcel: false,
            }).then((res) => {
              if (res.success) {
                accrualCalculateSscReCalculate({
                  ids: [id],
                  tableId: this.tableId,
                }).then((res1) => {
                  if (res1.success) {
                    this.$message.success("操作成功");
                    if (this.filter) {
                      this.loadList();
                    } else {
                      this.$emit("handleDrawerClose");
                    }
                    this.handleClose();
                  }
                });
              } else {
                let msg = res.result.split("<br/>");
                this.$message.error(msg[0]);
              }
            });
          }
        });
      }
      if (!this.models) {
        req.then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
            if (this.totalBonus && this.titName == "修改固定工资金额") {
              this.getBonusSalaryRecordList();
            }
            if (this.options) {
              this.loadList();
            }
            this.$emit("getbonusDataTableInfo");
            this.handleClose();
          }
        });
      }
    },
    //显示业绩弹窗
    showPerformanceDram(type, data) {
      this.drawType = type;
      this.DrawData = data;
      this.getInfoList();
      this.drawerVisible = true;
    },
    getInfoList() {
      let req = null;
      if (this.drawType == "Invoicing") {
        this.drawerName =
          "开票业绩--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusAchievementDetailInvoice({
          itemId: this.DrawData.itemId,
          page: this.page,
        }).then((res) => {
          if (res.success) {
            this.InfoData = res.result.records;
            this.page.total = res.result.total;
          }
        });
      } else if (this.drawType == "Payback") {
        this.drawerName =
          "回款业绩--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusAchievementDetailAccount({
          itemId: this.DrawData.itemId,
          page: this.page,
        }).then((res) => {
          if (res.success) {
            this.InfoData = res.result.records;
            this.page.total = res.result.total;
          }
        });
      } else if (this.drawType == "accruedBonus") {
        this.drawerName = "累计应发总奖金";
        bonusRecordItemAccruedBonusInfo({ itemId: this.DrawData.itemId }).then(
          (res) => {
            if (res.success) {
              this.InfoData = [res.result];
            }
          }
        );
      } else if (this.drawType == "accruedPaidBonus") {
        this.drawerName =
          "累计应发已计提--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusRecordItemAccruedBonusList({ id: this.DrawData.id }).then(
          (res) => {
            if (res.success) {
              this.InfoData = res.result;
            }
          }
        );
      } else if (this.drawType == "accruedSalary") {
        this.drawerName =
          "累计固定工资--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        this.getBonusSalaryRecordList();
      }
    },
    //获取
    getBonusSalaryRecordList() {
      bonusSalaryRecordList({ itemId: this.DrawData.itemId }).then((res) => {
        if (res.success) {
          this.InfoData = res.result.salaryRecords;
          this.totalSalary = res.result.totalSalary;
        }
      });
    },
    //导出
    downInfoExp() {
      this.btnLoading = true;
      let req = null;
      if (this.drawType == "Invoicing") {
        req = bonusAchievementDetailExportInvoice({
          itemId: this.DrawData.itemId,
          page: this.page,
        });
      } else if (this.drawType == "Payback") {
        req = bonusAchievementDetailExportAccount({
          itemId: this.DrawData.itemId,
          page: this.page,
        });
      } else if (this.drawType == "accruedPaidBonus") {
        req = bonusRecordItemExportAccruedBonus({
          id: this.DrawData.d,
        });
      } else if (this.drawType == "accruedSalary") {
        req = bonusSalaryRecordExport({ itemId: this.DrawData.itemId });
      }
      req.then((res) => {
        if (res.success) {
          location.href = this.baseUrl + res.result;
        }
        this.btnLoading = false;
      });
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getInfoList();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.page.size = val;
      this.page.current = 1;
      this.getInfoList();
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerResumeVisible = true;
      this.resumeId = id;
    },
    //测算
    measurements(data) {
      this.$emit("measurements", data);
      this.$emit("getChioceId", [data.id]);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.tableStatus == "PAID") {
        if (columnIndex <= 5) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      } else {
        if (this.options) {
          if (columnIndex <= 7) {
            const _row = this.spanArr[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            return {
              rowspan: _row,
              colspan: _col,
            };
          }
        } else {
          if (columnIndex <= 5) {
            const _row = this.spanArr[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            return {
              rowspan: _row,
              colspan: _col,
            };
          }
        }
      }
    },
    //获取重新测算数据
    getBonusCalculateSscReCalculate() {
      let req = null;
      if (this.tableStatus == "TO_PAY") {
        req = accrualCalculateSscReCalculateToPay({ ids: this.chioceIds });
      } else if (this.userIds && this.userIds.length > 0) {
        req = accrualCalculateSscCalculateUser({
          userIds: this.userIds,
          tableId: this.tableId,
        });
      } else {
        req = accrualCalculateSscReCalculate({
          ids: this.chioceIds,
          tableId: this.tableId,
        });
      }
      req.then((res) => {
        if (res.success) {
          this.ListData = res.result;
          let pos = 0;
          this.spanArr = [];
          for (var i = 0; i < this.ListData.length; i++) {
            if (i === 0) {
              this.spanArr.push(1);
              pos = 0;
            } else {
              // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
              if (this.ListData[i].id === this.ListData[i - 1].id) {
                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
              }
            }
          }
        }
      });
    },
    //显示固定工资修改记录
    showRecond(data, type) {
      let div = null;
      if (type == "accruedSalary") {
        div =
          "<p >修改前金额： <span style:'color:#F86002'>" +
          data.previousSalary +
          "</span></p>" +
          "<p >修改后金额：" +
          data.salary +
          "</p>" +
          "<p >修改原因： " +
          data.changeReason +
          "</p>";
      } else {
        div =
          "<p >修改前金额： <span style:'color:#F86002'>" +
          data.previousBonus +
          "</span></p>" +
          "<p >修改后金额：" +
          data.totalBonus +
          "</p>" +
          "<p >修改原因： " +
          data.bonusChangeReason +
          "</p>";
      }

      this.$confirm(div, "修改记录", {
        confirmButtonText: "关闭",
        showClose: false,
        customClass: "altCls",
        dangerouslyUseHTMLString: true,
        showCancelButton: false,
        closeOnClickModal: false,
      })
        .then(() => {})
        .catch(() => {});
    },
    //顾问数据处理
    handelConsultant(donsultantData, blon, id) {
      if (this.ListData.length > 0) {
        const userIds = [];
        this.ListData.forEach((element) => {
          userIds.push(element.userId);
        });
        this.handelConsultantOptions(userIds, donsultantData, blon, id);
        this.$emit("changeOptions", donsultantData);
      }
    },
    handelConsultantOptions(userIds, donsultantData, blon, id) {
      if (userIds.length > 0) {
        for (var i = 0; i < donsultantData.length; i++) {
          // if (blon) {
          if ("顾问" === donsultantData[i].type) {
            if (
              userIds.findIndex((item) => item === donsultantData[i].id) !== -1
            ) {
              donsultantData[i].disabled = blon;
            }
          } else {
            if (donsultantData[i].children != null) {
              this.handelConsultantOptions(
                userIds,
                donsultantData[i].children,
                blon,
                id
              );
            }
          }
        }
      }
    },
    getSearch(val) {
      this.filter.userIds = this.getuserIds.map((i) => {
        return i[i.length - 1];
      });
      this.submitForm();
    },
    getDepartmentIdsSearch(val) {
      this.filter.departmentIds = this.departmentIds.map((i) => {
        return i[i.length - 1];
      });
      this.submitForm();
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    // 顾问
    permissionTree(type) {
      permissionTree({
        type: type,
        showDisableUser: true,
        showLeaveStatus: true,
      }).then((res) => {
        if (res.success) {
          if (type == "顾问") {
            this.options1 = res.result;
          } else {
            this.options2 = res.result;
          }
        }
      });
    },
    getDepartmentId(index) {
      this.modelsData.departmentId =
        this.departmentId[this.departmentId.length - 1];
    },
  },
};
</script>

<style lang="scss">
.hoverIcon {
  color: #bbb;
  cursor: pointer;
}
.hoverIcon:hover {
  color: #526ecc;
}
</style>